import React, { useState,useEffect} from 'react';
import { TextField, Button, Box, Typography, Grid, Select, MenuItem,InputLabel, FormControl, Card,CardContent, Modal,IconButton,Paper} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import backgroundImage from '../assets/images/bg4.jpg'; // Path to your background image
import logo from '../assets/images/logo.png'; // Path to your background image
import { red } from '@mui/material/colors';
import { FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People'; // Icon cho Employees
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoIcon from '@mui/icons-material/Info';

import CloseIcon from '@mui/icons-material/Close';
import DangKyHocBong from './DangKyHocBong';
import DanhSachThiSinh from './DanhSachThiSinh';
import ThayDoiMatKhau from './ThayDoiMatKhau';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import SchoolIcon from '@mui/icons-material/School'; 
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ListIcon from '@mui/icons-material/List';
import LockIcon from '@mui/icons-material/Lock';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500], 
    },
    background: {
      default: '#ffffff', 
      paper: '#ffffff', 
    },
  },
  
});



// Định nghĩa style cho modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto', // Adjusted to auto to take the width of its content
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden', // Add overflow hidden to handle the case where the modal content is too wide
    maxWidth: 'calc(100% - 64px)', // Ensure there's some padding from the viewport edges
    maxHeight: 'calc(100% - 64px)', // Limit the height of the modal
    overflowY: 'auto', // Allow vertical scrolling within the modal
  };

 

  function StatisticsCard({ title, count, icon, iconBgColor, iconColor, borderColor }) {
    return (
      <Card sx={{ minWidth: 275, margin: 1, padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
  {count}
</Typography>
        </CardContent>
        {icon && (
          <Box sx={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20%',
            backgroundColor: iconBgColor || 'transparent', // Màu nền cho icon
            boxShadow: '0px 3px 6px #00000029', // Bóng mờ cho box
            border: borderColor ? `1px solid ${borderColor}` : 'none', // Viền nếu có
            marginRight: 2,
          }}>
            {React.cloneElement(icon, { style: { fontSize: '2rem', color: iconColor || 'inherit' } })} {/* Thay đổi kích thước và màu sắc icon */}
          </Box>
        )}
      </Card>
    );
  }
  
function AdminDAU() {
    const navigate = useNavigate();


    const [totalStudents, setTotalStudents] = useState(0);
    const [registeredStudents, setRegisteredStudents] = useState(0);
    const [enrolledStudents, setEnrolledStudents] = useState(0);



// Thêm vào phần đầu của component AdminPage
const [soThiSinhDangKy, setSoThiSinhDangKy] = useState(0);
const [soThiSinhNhapHoc, setSoThiSinhNhapHoc] = useState(0);
const [tongSoThiSinh, setTongSoThiSinh] = useState(0);
useEffect(() => {
  // Lấy thông tin người dùng từ localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const maTruong = userInfo?.maTruong; // Sử dụng optional chaining nếu có thể userInfo là null

  if (maTruong) {
    // Gọi API để lấy số lượng thí sinh đăng ký
    fetch(`${process.env.REACT_APP_API_URL}/api/sothihsinhdangky?maTruong=${maTruong}`)
      .then(response => response.json())
      .then(data => setSoThiSinhDangKy(data.soThiSinhDangKy))
      .catch(error => console.error('Error fetching data: ', error));

    // Gọi API để lấy số lượng thí sinh đã nhập học
    fetch(`${process.env.REACT_APP_API_URL}/api/sothihsinhnhaphoc?maTruong=${maTruong}`)
      .then(response => response.json())
      .then(data => setSoThiSinhNhapHoc(data.soThiSinhNhapHoc))
      .catch(error => console.error('Error fetching data: ', error));
  } else {
    console.error('Mã trường không có sẵn');
  }
}, []); // Đảm bảo rằng dependency array chỉ chứa các item cần thiết
useEffect(() => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const maTruong = userInfo?.maTruong;

  if (maTruong) {
    // Gọi API để lấy tổng số thí sinh
    fetch(`${process.env.REACT_APP_API_URL}/api/tongsothihsinh?maTruong=${maTruong}`)
      .then(response => response.json())
      .then(data => setTongSoThiSinh(data.tongSoThiSinh))
      .catch(error => console.error('Error fetching data: ', error));
  } else {
    console.error('Mã trường không có sẵn');
  }
}, []); // Chỉ chạy một lần khi component được mount
     
  // Hàm để xử lý việc thoát và quay lại trang đăng nhập
  const handleLogout = () => {
     // Xóa thông tin người dùng khỏi localStorage
  localStorage.removeItem('userInfo');
  localStorage.removeItem('tenTruong');

    // Chuyển hướng về trang đăng nhập
    navigate('/login');
  };

  const [tenTruong, setTenTruong] = useState(''); // State để lưu trữ tên trường
  const [maTruong, setMaTruong] = useState(''); // State để lưu trữ mã trường
  
  useEffect(() => {
    // Lấy thông tin người dùng từ localStorage
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      // Cập nhật tên trường vào state
      setTenTruong(userInfo.tenTruong);
      setMaTruong(userInfo.maTruong); // Giả sử 'maTruong' là key chứa mã trường trong object userInfo
    } else {
      // Nếu không có thông tin, có thể redirect người dùng về trang đăng nhập
      navigate('/login');
    }
  }, [navigate]);


  const [modalState, setModalState] = useState({
    register: false,
    list: false,
    changePassword: false,
  });

  const handleOpenModal = (modalName) => {
    setModalState({ ...modalState, [modalName]: true });
  };

  const handleCloseModal = (modalName) => {
    setModalState({ ...modalState, [modalName]: false });
  };


  
  const icons = [
    {
      icon: <PersonAddIcon sx={{ fontSize: '2rem' }} />,
      text: 'Đăng ký thí sinh',
      modalName: 'register',
    },
    {
      icon: <ListIcon sx={{ fontSize: '2rem' }} />,
      text: 'Danh sách thí sinh',
      modalName: 'list',
    },
    {
      icon: <LockIcon sx={{ fontSize: '2rem' }} />,
      text: 'Đổi mật khẩu',
      modalName: 'changePassword',
    },
  ];




  return (
    <ThemeProvider theme={theme}>
    <Box
    sx={{
      width: '100vw',
      height: '100vh',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'column', // Stack items vertically
      alignItems: 'center',
      justifyContent: 'flex-start', // Align to the top
    }}
  >
    {/* Top bar container */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between', // Space between logo and login button
        alignItems: 'center',
        width: '100%', // Full width
        padding: '20px', // Padding on all sides
        boxSizing: 'border-box', // Include padding in width calculation
      }}
    >
      {/* Empty div to balance the space on the left side */}
      <div style={{ width: '100px' }}></div>

      {/* Centered logo */}
      <img src={logo} alt="Logo" style={{ maxWidth: '100px' }} />

      
    <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    // Đặt style chung ở đây
    '& svg': {
      fontSize: '2rem', // Kích thước ban đầu của icon
      color:'#051129',
    },
    '& .MuiTypography-button': {
      fontSize: '1rem', // Kích thước ban đầu của chữ
    },
    // Áp dụng các style đáp ứng cho màn hình nhỏ
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: '1.5rem', // Kích thước nhỏ hơn của icon
      },
      '& .MuiTypography-button': {
        fontSize: '0.875rem', // Kích thước nhỏ hơn của chữ
      },
    },
  }}
  onClick={handleLogout} // Thay đổi hàm được gọi khi nhấn vào
>
  <ExitToAppIcon sx={{ marginRight: '8px' }} />
  <Typography variant="button" color="#051129">
  Thoát
  </Typography>
</Box>
    </Box>

    {/* Rest of the content centered in the middle of the page */}
    <Box
      sx={{
        width: '100%',
        maxWidth: 960, // Adjusted for wider form
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 1,
        boxShadow: 3,
        mt: '20px', // Add margin at the top to push it down from the top bar
        zIndex: 1, // Ensure this box is above the absolute elements
        // margin: 'auto', // Căn giữa trên mọi kích thước màn hình
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%', // Kích thước tối đa nhỏ hơn trên màn hình nhỏ
      mt: 2, // Khoảng cách nhỏ hơn từ top bar
      p: 1, // Padding nhỏ hơn
    },
      }}
    >
        <Typography
                component="h1"
                variant="h5"
                align="center"
                sx={{
                    marginBottom: '30px', // Adds 20px bottom margin
                }}
            >
              Xin chào Admin:  {tenTruong} - {maTruong} {/* Sử dụng state tenTruong ở đây */}
            </Typography>


            
            <Box sx={{ flexGrow: 1 }}>
  <Grid container justifyContent="center" alignItems="center" spacing={2}>
    <Grid item xs={12} sm={6} md={4}>
      <StatisticsCard
        title="Tổng thí sinh"
        count={tongSoThiSinh}
        icon={<AssignmentIndIcon />}
        iconBgColor="#FFE4E4"
        iconColor="#FF4D49"
        borderColor="#FFE4E4"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <StatisticsCard
        title="Thí sinh đăng ký"
        count={soThiSinhDangKy}
        icon={<PeopleIcon />}
        iconBgColor="#EAFBDF"
        iconColor="#72E128"
        borderColor="#EAFBDF"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <StatisticsCard
        title="Thí sinh nhập học"
        count={soThiSinhNhapHoc}
        icon={<SchoolIcon />}
        iconBgColor="#FFF4DF"
        iconColor="#FDB528"
        borderColor="#FFF4DF"
      />
    </Grid>
    {/* Thêm các Grid item khác nếu bạn có */}
  </Grid>
</Box>
    <Box display="flex" justifyContent="space-around" alignItems="center">
      {icons.map((feature, index) => (
        <Box key={index} onClick={() => handleOpenModal(feature.modalName)} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: '50%',
              p: 2,
              mb: 1,
              borderColor: theme.palette.primary.main,
              borderWidth: '2px',
              borderStyle: 'dotted',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                cursor: 'pointer'
              },
              '& .MuiSvgIcon-root': { // Direct descendant selector for icons
                fontSize: '2rem',
                color: theme.palette.primary.main,
              }
            }}
          >
            {feature.icon}
          </Paper>
          <Typography
  variant="body1" // Hoặc sử dụng "body2" để có kích thước nhỏ hơn một chút
  align="center"
  sx={{
    color: theme.palette.primary.main,
    fontSize: '1rem' // Thay đổi kích thước chữ tùy ý, ví dụ: 1rem
  }}
>
  {feature.text}
</Typography>
        </Box>
      ))}
    </Box>
<Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap:4, // khoảng cách giữa các card
        p: 4, // padding xung quanh
      }}
    >


      

      </Box>
    </Box>
    
    {/* Modals for each action */}
    <Modal
  open={modalState.register}
  // onClose={() => handleCloseModal('register')}
  // aria-labelledby="modal-title-register"
  // aria-describedby="modal-description-register"
  // disableBackdropClick // Prevent modal close on backdrop click
  // disableEscapeKeyDown // Prevent modal close on escape key press
  onClose={() => {}}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
  disableBackdropClick // Prevent modal close on backdrop click
  disableEscapeKeyDown // Prevent modal close on escape key press
>
  <Box sx={modalStyle}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
      <Typography variant="h6">Đăng ký thí sinh</Typography>
      <IconButton onClick={() => handleCloseModal('register')}>
        <CloseIcon />
      </IconButton>
    </Box>
    <DangKyHocBong />
  </Box>
</Modal>
      
      <Modal
        open={modalState.list}
        // onClose={() => handleCloseModal('list')}
        // aria-labelledby="modal-title-list"
        // aria-describedby="modal-description-list"
        onClose={() => {}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick // Prevent modal close on backdrop click
        disableEscapeKeyDown // Prevent modal close on escape key press
      >
        <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
      <Typography variant="h6">Danh sách thí sinh</Typography>
      <IconButton onClick={() => handleCloseModal('list')}>
        <CloseIcon />
      </IconButton>
    </Box>
          <DanhSachThiSinh />
        </Box>
      </Modal>
      
      <Modal
        open={modalState.changePassword}
        // onClose={() => handleCloseModal('changePassword')}
        
        // aria-labelledby="modal-title-changePassword"
        // aria-describedby="modal-description-changePassword"
        onClose={() => {}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick // Prevent modal close on backdrop click
        disableEscapeKeyDown // Prevent modal close on escape key press
      >
        {/* Replace the content of this modal with your Change Password component */}
        <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
      <Typography variant="h6">Đổi mật khẩu</Typography>
      <IconButton onClick={() => handleCloseModal('changePassword')}>
        <CloseIcon />
      </IconButton>
    </Box>
    <ThayDoiMatKhau />
        </Box>
      </Modal>
      </Box>

  </ThemeProvider>
  );
}

export default AdminDAU;