// import React from 'react';
// import RegistrationForm from './components/RegistrationForm';

// function App() {
//   return (
//     <div>
//       <RegistrationForm />
//     </div>
//   );
// }

// export default App;
import React from 'react';
import { BrowserRouter as Router, Routes, Route,   } from 'react-router-dom';
import RegistrationForm from './components/RegistrationForm';
import Login from './components/Login';
import AdminPage from './components/AdminPage';
import DangKyHocBong from './components/DangKyHocBong';
import DanhSachThiSinh from './components/DanhSachThiSinh';
import ThayDoiMatKhau from './components/ThayDoiMatKhau';
import AdminDAU from './components/AdminDAU';
import TheLeChuongTrinh from './components/TheLeChuongTrinh';
import RegistrationFormZalo from './components/RegistrationFormZalo';

function App() {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul>
            
            <li>
              <Link to="/login">Đăng nhập</Link>
            </li>
          </ul>
        </nav> */}

        <Routes>
  <Route path="/" element={<RegistrationForm />} />
  <Route path="/login" element={<Login />} />
  <Route path="/admin" element={<AdminPage />} />
  <Route path="/dangkyhocbong" element={<DangKyHocBong />} />
  <Route path="/danhsachthisinh" element={<DanhSachThiSinh />} />
  <Route path="/thaydoimatkhau" element={<ThayDoiMatKhau />} />
  <Route path="/admindau" element={<AdminDAU />} />
  <Route path="/thelechuongtrinh" element={<TheLeChuongTrinh />} />
  <Route path="/dangkyhocbongzalo" element={<RegistrationFormZalo />} />
  {/* Bỏ đường dẫn "/register" nếu bạn không cần nữa */}
</Routes>
      </div>
    </Router>
  );
}

export default App;