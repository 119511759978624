

import React, { useState,useEffect} from 'react';
import {TextField,Modal, Button, Box, Typography, Grid, Select, MenuItem, ListSubheader, InputLabel, FormControl,Snackbar, Link,Divider , List, ListItem} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import backgroundImage from '../assets/images/bg4.jpg'; // Path to your background image
import logo from '../assets/images/logo.png'; // Path to your background image
import { red } from '@mui/material/colors';
import { FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import TheLeChuongTrinh from './TheLeChuongTrinh';
import useMediaQuery from '@mui/material/useMediaQuery';
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500], // Replace with your primary color
    },
    background: {
      default: '#ffffff', // Replace with your background color
      paper: '#ffffff', // Replace with your paper color
    },
  },
  // You can also add custom typography, shape, etc. to match your login page
});

// Array of ngành kỹ thuật
const nganhKyThuat = [
  'Kỹ thuật xây dựng công trình giao thông',
  'Kỹ thuật cơ sở hạ tầng',
  'Quy hoạch vùng và đô thị',
  'Kỹ thuật xây dựng',
  'Quản lý xây dựng',
  'Công nghệ kỹ thuật điện, điện tử',
];

// Array of ngành Kinh tế
const nganhKinhTe = [
  'Quản trị khách sạn',
  'Ngôn ngữ Anh',
  'Quản trị kinh doanh',
  'Kế toán',
  'Tài chính ngân hàng',
];

function RegistrationFormZalo() {
  // const [open, setOpen] = useState(true); // State to handle the visibility
// Define the keyframes for the pulse animation
const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

// Create a styled button with the animation
const PulsingButton = styled(Button)`
  animation: ${pulseAnimation} 2s infinite;
`;
  const [open, setOpen] = React.useState(false);
 

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };




    const [formData, setFormData] = useState({
        hoTen: '',
        canCuocCongDan: '',
        ten_tinh: '', // Sử dụng nếu cột cơ sở dữ liệu là 'ten_tinh'
        ten_truongTHPT: '', // Sử dụng nếu cột cơ sở dữ liệu là 'ten_truongTHPT'
        email: '',
        soDienThoai: '',
        nganh: '',
        namtuyensinh: '2024', // Giá trị mặc định là 2024
        tenLop: '' // Thêm mới trường lớp
    });
    const [tinhList, setTinhList] = useState([]); // Danh sách tỉnh
    const [truongTHPTList, setTruongTHPTList] = useState([]); // Danh sách trường THPT dựa trên tỉnh đã chọn
    const [formErrors, setFormErrors] = useState({});

    const navigate = useNavigate();

  // Function to handle login navigation
  const handleLogin = () => {
    navigate('/login');
  };

    
    useEffect(() => {
        // Giả sử API của bạn trả về danh sách tỉnh như sau
        fetch(`${process.env.REACT_APP_API_URL}/api/tinh`)
            .then(response => response.json())
            .then(data => setTinhList(data));
    }, []);

    useEffect(() => {
        if (formData.tinh) {
            // Giả sử API của bạn trả về danh sách trường THPT dựa trên id của tỉnh đã chọn
            fetch(`${process.env.REACT_APP_API_URL}/api/truongTHPT/${formData.tinh}`)
                .then(response => response.json())
                .then(data => setTruongTHPTList(data));
        }
    }, [formData.tinh]);

 
   
    



    const handleChange = (event) => {
    const { name, value } = event.target;
     // Validate cho trường căn cước công dân
     if (name === 'canCuocCongDan') {
      if (!/^\d{12}$/.test(value)) {
          // Set lỗi nếu không phải là 12 số
          setFormErrors({
              ...formErrors,
              canCuocCongDan: 'Căn cước công dân phải là 12 số.',
          });
      } else {
          // Clear lỗi nếu đúng
          setFormErrors({
              ...formErrors,
              canCuocCongDan: '',
          });
      }
  }
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear errors if the value is valid
  if (value) {
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  }
    if (name === 'tinh') {
        const tinhSelected = tinhList.find(tinh => tinh.id.toString() === value);
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
            ten_tinh: tinhSelected ? tinhSelected.tenTinh : '',
            ten_truongTHPT: '' // Reset giá trị này vì tỉnh đã thay đổi
        }));
    } else if (name === 'truongTHPT') {
        const truongSelected = truongTHPTList.find(truong => truong.id.toString() === value);
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
            ten_truongTHPT: truongSelected ? truongSelected.tenTruong : ''
        }));
    } else {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }
};
const [showConfirmationModal, setShowConfirmationModal] = useState(false);



    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
    
    // Check for required fields and set error messages
    if (!formData.hoTen.trim()) errors.hoTen = "Họ và tên là bắt buộc.";
    if (!formData.canCuocCongDan.trim()) {
      errors.canCuocCongDan = "Số căn cước công dân là bắt buộc.";
  } else if (!/^\d{12}$/.test(formData.canCuocCongDan)) {
      errors.canCuocCongDan = "Số căn cước công dân phải là 12 chữ số.";
  }
    if (!formData.tinh) errors.tinh = "Vui lòng chọn Tỉnh.";
    if (!formData.truongTHPT) errors.truongTHPT = "Vui lòng chọn Trường THPT.";
    if (!formData.email.trim()) errors.email = "Email là bắt buộc.";
    if (!formData.soDienThoai.trim()) errors.soDienThoai = "Số điện thoại là bắt buộc.";
    if (!formData.nganh.trim()) errors.nganh = "Ngành xét tuyển là bắt buộc.";
    if (!formData.tenLop.trim()) errors.tenLop = "Lớp là bắt buộc.";

// Validate email format again on submit
if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
  errors.email = 'Email không hợp lệ.';
}
    setFormErrors(errors);

     // If there are errors, do not submit the form
     if (Object.keys(errors).length > 0) return;
        // Log dữ liệu formData để kiểm tra trước khi gửi
        console.log('Sending formData to server:', formData);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    hoTen: formData.hoTen,
                    canCuocCongDan: formData.canCuocCongDan,
                    tinhId: formData.tinh, // Gửi ID của tỉnh
                    truongTHPTId: formData.truongTHPT, // Gửi ID của trường THPT
                    email: formData.email,
                    soDienThoai: formData.soDienThoai,
                    nganh: formData.nganh, // Make sure to include the nganh field
                    namtuyensinh: formData.namtuyensinh, // Include namtuyensinh as well
                    tenLop: formData.tenLop // Include tenLop as well
                })
            });
    
            if (response.ok) {
                console.log('Registration successful');
                setShowConfirmationModal(true);
//                 setSnackbarMessage("Chúc mừng bạn đã đăng ký thành công! - Vui lòng chờ trong giây lát để chuyển qua trang Đăng ký xét tuyển");
// setSnackbarSeverity('success');
// setOpenSnackbar(true);
//     setTimeout(() => {
//       window.location.href = 'https://xettuyen.dau.edu.vn';
//   }, 3000);
                // Reset form after successful registration
                setFormData({
                    hoTen: '',
                    canCuocCongDan: '',
                    tinh: '', // Reset ID của tỉnh
                    truongTHPT: '', // Reset ID của trường THPT
                    email: '',
                    soDienThoai: '',
                    nganh: '',
                    tenLop: ''
                });
                // Thông báo cho người dùng rằng đăng ký thành công
                // alert("Đăng ký thành công!");
            } else {
                // Xử lý lỗi phản hồi không thành công từ server
                setSnackbarMessage("Đăng ký thất bại! Vui lòng thử lại.");
setSnackbarSeverity('error');
setOpenSnackbar(true);
            }
        } catch (error) {
          setSnackbarMessage("Lỗi kết nối đến server. Vui lòng thử lại sau.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
        }
    };


    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' hoặc 'error'
    const [openSnackbar, setOpenSnackbar] = useState(false);
// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

 
  return (
    <ThemeProvider theme={theme}>

<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert 
          onClose={() => setOpenSnackbar(false)} 
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
</Snackbar>
    <Box
    sx={{
      width: '100vw',
      maxWidth: '100%',
      minHeight: '100vh', // Đổi height thành minHeight
      // backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'column', // Stack items vertically
      alignItems: 'center',
      justifyContent: 'flex-start', // Align to the top
     [theme.breakpoints.down('sm')]: {
         maxWidth: '100%',
       mb:5,
         
       },
    }}
  >
    
    {/* Rest of the content centered in the middle of the page */}
    <Box
      sx={{
        width: '100%',
        maxWidth: 720, // Adjusted for wider form
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 4,
        boxShadow: 3,
        mt: '20px',
        
        zIndex: 1, // Ensure this box is above the absolute elements
        // margin: 'auto', // Căn giữa trên mọi kích thước màn hình
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%', // Kích thước tối đa nhỏ hơn trên màn hình nhỏ
      mt: 2, // Khoảng cách nhỏ hơn từ top bar
      p: 1, // Padding nhỏ hơn
     
      
    },
      }}
    >

     
<Box sx={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 3,
}}>
  <PulsingButton
    variant="outlined"
    color="info"
    startIcon={<ContactSupportIcon />}
    onClick={handleOpen} // Replace with your event handler
    sx={{
      borderRadius: '20px', // Rounded corners
      textTransform: 'none', // Prevents uppercase transformation
      fontSize: '1rem', // Adjust the font size as needed
      padding: '6px 12px', // Adjust padding as needed
      '& .MuiButton-startIcon': {
        marginRight: '4px', // Adjust space between icon and text
        '& .MuiSvgIcon-root': {
          fontSize: '1.25rem',
        },
      },
      '& .MuiButton-label': {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inherit',
      },
    }}
  >
    Thể lệ chương trình
  </PulsingButton>
</Box>
<div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        
       
        <Box sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          maxHeight: '80vh',
          width: fullScreen ? '100%' : '50%',
          height: fullScreen ? '100vh' : 'auto',
          overflow: 'auto', // Prevent scrolling outside the content area
          position: 'relative',
          mx: fullScreen ? 0 : 'auto' 
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            Thể lệ chương trình
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ my: 2 }} /> {/* This will add a horizontal line */}
          <Box sx={{ overflowY: 'auto', maxHeight: 'calc(80vh - 96px)' }}> {/* Here is the scrollable area */}
          <TheLeChuongTrinh />
         </Box>
         </Box>
      </Modal>
    </div>

    <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Họ và tên"
                name="hoTen"
                value={formData.hoTen}
                onChange={handleChange}
                fullWidth
                
                error={!!formErrors.hoTen}
    helperText={formErrors.hoTen}
    required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Số căn cước công dân"
                name="canCuocCongDan"
                value={formData.canCuocCongDan}
                onChange={handleChange}
                fullWidth
                error={!!formErrors.canCuocCongDan}
    helperText={formErrors.canCuocCongDan}
    required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.tinh}>
            <InputLabel id="tinh-label">Chọn Tỉnh </InputLabel>
              <Select
                labelId="tinh-label"
                label="Chọn Tỉnh"
                name="tinh"
                value={formData.tinh}
                onChange={handleChange}
                fullWidth
                required
                
              >
                
                <MenuItem value="" disabled>
                  <em>Chọn Tỉnh</em>
                </MenuItem>
                {tinhList.map((tinh) => (
                  <MenuItem key={tinh.id} value={tinh.id}>
                    {tinh.tenTinh}
                  </MenuItem>
                ))}
                
              </Select>
              {!!formErrors.tinh && (
        <FormHelperText>{formErrors.tinh}</FormHelperText>
    )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>

            <FormControl fullWidth error={!!formErrors.truongTHPT}>
            <InputLabel id="truongTHPT-label">Trường THPT</InputLabel>
              <Select
                labelId="truongTHPT-label"
                label="Trường THPT"
                name="truongTHPT"
                value={formData.truongTHPT}
                onChange={handleChange}
                fullWidth
                
                disabled={!formData.tinh}
              >
                <MenuItem value="" disabled>
                  <em>Chọn Trường THPT</em>
                </MenuItem>
                {truongTHPTList.map((truong) => (
                  <MenuItem key={truong.id} value={truong.id}>
                    {truong.tenTruong}
                  </MenuItem>
                ))}
              </Select>
              {!!formErrors.truongTHPT && (
        <FormHelperText>{formErrors.truongTHPT}</FormHelperText>
    )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
    label="Tên lớp"
    name="tenLop"
    value={formData.tenLop}
    onChange={handleChange}
    fullWidth
    required
    error={!!formErrors.tenLop}
    helperText={formErrors.tenLop}
    
/>
</Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                error={!!formErrors.email}
    helperText={formErrors.email}
    required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Số điện thoại"
                name="soDienThoai"
                value={formData.soDienThoai}
                onChange={handleChange}
                fullWidth
                error={!!formErrors.soDienThoai}
    helperText={formErrors.soDienThoai}
    required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.nganh}>
            <InputLabel 
    id="nganh-label" 
    
   
  >
   Chọn Ngành xét tuyển
  </InputLabel>
  <Select
    labelId="nganh-label"
    label="Chọn Ngành xét tuyển"
    name="nganh"
    value={formData.nganh}
    onChange={handleChange}
    required
    error={!!formErrors.nganh}
    helperText={formErrors.nganh}
    fullWidth
  >
    <MenuItem value="">
      <em>Chọn Ngành</em>
    </MenuItem>
    <ListSubheader>Khối ngành kỹ thuật - 100% Học phí</ListSubheader>
      {nganhKyThuat.map((nganh) => (
        <MenuItem key={nganh} value={nganh}>
          {nganh}
        </MenuItem>
      ))}
      <ListSubheader>Khối ngành Kinh tế, Ngoại ngữ - 50% Học phí</ListSubheader>
      {nganhKinhTe.map((nganh) => (
        <MenuItem key={nganh} value={nganh}>
          {nganh}
        </MenuItem>
    ))}
  </Select>
  {!!formErrors.nganh && <FormHelperText>{formErrors.nganh}</FormHelperText>}
</FormControl>
            </Grid>

            
            <form>
  {/* Các trường khác của form */}

  {/* Trường ẩn chứa giá trị năm xét tuyển */}
  <input
    type="hidden"
    name="namtuyensinh"
    value="2024"
  />
</form>

<Grid container justifyContent="center">
  <Grid item xs={6}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      sx={{ mt: 3, mb: 2 }}
    >
      ĐĂNG KÝ
    </Button>
  </Grid>
</Grid>

<div>
<Modal
  open={showConfirmationModal}
  onClose={() => setShowConfirmationModal(false)}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
  onClose={handleClose}
  closeAfterTransition
 
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  
 
  <Box sx={{
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    maxHeight: '80vh',
    width: fullScreen ? '100%' : '50%',
    height: fullScreen ? '100vh' : 'auto',
    overflow: 'auto', // Prevent scrolling outside the content area
    position: 'relative',
    mx: fullScreen ? 0 : 'auto' 
  }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
      ĐIỀU KIỆN ĐIỂM XÉT HỌC BỔNG
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
    <Divider sx={{ my: 2 }} /> {/* This will add a horizontal line */}
    <Box sx={{ overflowY: 'auto', maxHeight: 'calc(80vh - 96px)' }}> {/* Here is the scrollable area */}
     
    <Typography>
        Để nhận được Học bổng <Typography component="span" sx={{ fontWeight: 'bold' }}>Khơi nguồn trí tuệ năm 2024</Typography>, bạn cần đáp ứng <Typography component="span" sx={{ fontWeight: 'bold' }}>MỘT</Typography> trong những yêu cầu dưới đây:
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: -1, // Khoảng cách dưới tiêu đề
        }}
      >
        1. Xét tuyển theo học bạ THPT:
      </Typography>
      <List sx={{ marginLeft: 3, marginTop: 0, marginBottom: 0 }}> {/* Điều chỉnh lề và khoảng cách */}
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Điểm Trung bình 5 học kỳ (HK) (HK1,2 lớp 10,11 và HK1 lớp 12) <Typography component="span" sx={{ fontWeight: 'bold' }}>&gt;=  7.0 điểm.</Typography>
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Hoặc Điểm TBCN 12 <Typography component="span" sx={{ fontWeight: 'bold' }}>&gt;= 7.0 điểm.</Typography>
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Hoặc Tổng điểm 3 môn 1 trong 06 tổ hợp sau (A00, A01, B00, D01, D14, D15) <Typography component="span" sx={{ fontWeight: 'bold' }}>&gt;=  21 điểm.</Typography>
        </ListItem>
      </List>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: -1, // Khoảng cách dưới tiêu đề
        }}
      >
        2.Xét tuyển theo điểm thi tốt nghiệp THPT 2024:
      </Typography>
      <List sx={{ marginLeft: 3, marginTop: 0, marginBottom: 0 }}> {/* Điều chỉnh lề và khoảng cách */}
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Tổng điểm 3 môn 1 trong 06 tổ hợp sau (A00, A01, B00, D01, D14, D15) <Typography component="span" sx={{ fontWeight: 'bold' }}>&gt;=  17 điểm.</Typography>
        </ListItem>
      </List>
      <Typography>
        Trong trường hợp không đủ điều kiện nêu trên, bạn vẫn có thể nhận được học bổng từ chương trình <Typography component="span" sx={{ fontWeight: 'bold' }}>Học bổng Chắp cánh ước mơ dành cho tân sinh viên</Typography> (giảm 10% học phí học kỳ đầu tiên).
      </Typography>
      <Button
  type="button"
  variant="contained"
  sx={{
    mt: 2,
    mb: 1,
    backgroundColor: '#1976d2', // Màu xanh dương
    color: 'white', // Đặt màu chữ thành trắng cho nổi bật
    '&:hover': {
      backgroundColor: '#115293', // Màu xanh dương đậm hơn khi hover
    },
  }}
  fullWidth
  onClick={() => {
    window.location.href = 'https://tuyensinh.dau.edu.vn/hoc-bong-chap-canh-uoc-mo-2024.html';
  }}
>
  Học bổng chắp cánh ước mơ
</Button>
<Button
  type="button" // Cập nhật loại thành button để tránh gửi form
  variant="contained"
  sx={{
    mt: 1,
    mb: 2,
    backgroundColor: '#d32f2f', // Màu đỏ
    color: 'white', // Đặt màu chữ thành trắng cho nổi bật
    '&:hover': {
      backgroundColor: '#9a0007', // Màu đỏ đậm hơn khi hover
    },
  }}
  fullWidth
  onClick={() => {
    window.location.href = 'https://xettuyen.dau.edu.vn';
  }}
>
  Đăng ký xét tuyển
</Button>
</Box>
  </Box>
</Modal>
</div>
            
          </Grid>
        </form>
      </Box>
      
    </Box>
    


  
  </ThemeProvider>
  );
}

export default RegistrationFormZalo;