import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import {IconButton, Button} from '@mui/material';
import { Select, MenuItem, Menu } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import BackupTableIcon from '@mui/icons-material/BackupTable';
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500],
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
  },
});

  


function DanhSachThiSinh() {
  const [data, setData] = useState([]);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

// Hàm chuyển đổi dữ liệu sang định dạng CSV
// const exportToCSV = (apiData, fileName) => {
//     const fileType = 'text/csv;charset=UTF-8';
//     const fileExtension = '.csv';

//     const csvData = apiData.map(row => ({
//       id: row.id,
//       ho_ten: row.ho_ten,
//       can_cuoc_cong_dan: row.can_cuoc_cong_dan,
//       email: row.email,
//       so_dien_thoai: row.so_dien_thoai,
//       status: row.Status,
//     }));

//     const csvString = [
//       ["ID", "Họ tên", "Căn cước công dân", "Email", "Số điện thoại", "Trạng thái"],
//       ...csvData.map(item => [
//         item.id, item.ho_ten, item.can_cuoc_cong_dan, item.email, item.so_dien_thoai, item.status
//       ])
//     ].map(e => e.join(",")).join("\n");

//     const blob = new Blob([csvString], { type: fileType });
//     FileSaver.saveAs(blob, fileName + fileExtension);
//   };
const exportToCSV = (apiData, fileName) => {
  const fileType = 'text/csv;charset=UTF-8';
  const fileExtension = '.csv';

  // Đảm bảo rằng các trường dữ liệu phải khớp với những gì có trong apiData
  const csvData = apiData.map(row => ({
      id: row.id,
      ho_ten: row.ho_ten,
      can_cuoc_cong_dan: row.can_cuoc_cong_dan,
      email: row.email,
      so_dien_thoai: row.so_dien_thoai,
      ten_tinh: row.ten_tinh,
      ten_truongTHPT: row.ten_truongTHPT,
      maTruong: row.maTruong,
      nganh: row.nganh,
      namtuyensinh: row.namtuyensinh,
      status: row.Status
  }));

  // Thêm tiêu đề cột cho các trường mới
  const csvHeader = [
      "ID", "Họ tên", "Căn cước công dân", "Email", 
      "Số điện thoại", "Tên tỉnh", "Tên trường THPT", 
      "Mã trường", "Ngành", "Năm", "Trạng thái"
  ];

  // Thêm dữ liệu vào từng hàng trong CSV
  const csvRows = csvData.map(item => [
      item.id, item.ho_ten, item.can_cuoc_cong_dan, item.email, 
      item.so_dien_thoai, item.ten_tinh, item.ten_truongTHPT, 
      item.maTruong, item.nganh, item.namtuyensinh, item.status
  ]);

  // Tạo chuỗi CSV bằng cách nối tiêu đề và dữ liệu
  const csvString = [csvHeader, ...csvRows]
      .map(row => row.join(","))
      .join("\n");

  // Tạo Blob từ chuỗi CSV và sử dụng FileSaver để tải về
  const blob = new Blob([csvString], { type: fileType });
  FileSaver.saveAs(blob, fileName + fileExtension);
};
  // Hàm chuyển đổi dữ liệu sang định dạng Excel (XLSX)
  const exportToExcel = (apiData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const excelData = apiData.map(row => ({
      ID: row.id,
      'Họ tên': row.ho_ten,
      'Căn cước công dân': row.can_cuoc_cong_dan,
      'Email': row.email,
      'Số điện thoại': row.so_dien_thoai,
      'Tên tỉnh': row.ten_tinh, // Giả sử đây là trường đúng trong row
      'Tên trường THPT': row.ten_truongTHPT, // Giả sử đây là trường đúng trong row
      'Tên trường THPT': row.ten_truongTHPT, // Giả sử đây là trường đúng trong row
      'Mã trường': row.maTruong, // Giả sử đây là trường đúng trong row
      'Ngành': row.nganh, // Giả sử đây là trường đúng trong row
      'Năm': row.namtuyensinh, // Giả sử đây là trường đúng trong row
      'Trạng thái': row.Status,
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  };



// Hàm xử lý việc xóa dữ liệu
const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xóa bản ghi này không?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/dangkyhocbong/delete/${id}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          // Xóa thành công, cập nhật trạng thái
          setData((prevData) => prevData.filter((row) => row.id !== id));
        } else {
          // Xử lý lỗi phản hồi từ server
          console.error('Không thể xóa bản ghi, phản hồi từ server:', response.status);
          alert('Không thể xóa bản ghi');
        }
      } catch (error) {
        // Xử lý lỗi mạng hoặc khi không thể gửi request
        console.error('Lỗi mạng hoặc không thể gửi request:', error);
        alert('Lỗi mạng hoặc không thể gửi request');
      }
    }
  };
 
  const [rows, setRows] = useState([]);
  const handleProcessRowUpdate = async (newRow) => {
    // Xử lý cập nhật dữ liệu vào state hoặc gửi lên server tại đây
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/dangkyhocbong/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: newRow.id,
          ho_ten: newRow.ho_ten,
          can_cuoc_cong_dan: newRow.can_cuoc_cong_dan,
          email: newRow.email,
          so_dien_thoai: newRow.so_dien_thoai,
          tenLop: newRow.tenLop,
          // Status: newRow.Status, // Cập nhật để gửi trường 'status'
          Status: newRow.Status, // Sử dụng giá trị Status từ newRow
          // Không gửi trường 'status' vì nó không được phép cập nhật
        }),
      });
  
      if (response.ok) {
        // Cập nhật dữ liệu mới vào state nếu cần
        const updatedRow = await response.json();
        // Giả sử server trả về dữ liệu đã cập nhật
        // Bạn cần update dữ liệu vào state ở đây nếu cần
       
            // Cập nhật state rows ở đây
            setRows((prevRows) => 
            prevRows.map((row) => 
              row.id === updatedRow.id ? { ...row, ...updatedRow } : row
            )
          );
  
        return updatedRow;
      } else {
        // Xử lý trường hợp response không ok (vd: lỗi 400, 500)
        console.error('Cập nhật không thành công');
        // Trả về dòng dữ liệu cũ
        return data.find((row) => row.id === newRow.id);
      }
    } catch (error) {
      // Xử lý lỗi khi không thể gửi request tới server
      console.error('Lỗi mạng hoặc lỗi server:', error);
      // Trả về dòng dữ liệu cũ
      return data.find((row) => row.id === newRow.id);
    }
  };

  const [isAdmin, setIsAdmin] = useState(false);

useEffect(() => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  setIsAdmin(userInfo?.role === 'admin');
}, []);
// Tạo các cột mới cho người dùng admin
// This function might be called when a selection is made in the dropdown

const adminColumns = [
 
  { field: 'id', headerName: 'ID', width: 30 },
    { field: 'ho_ten', headerName: 'Họ tên', width: 160, editable: true, },
    { field: 'can_cuoc_cong_dan', headerName: 'Căn cước công dân', width: 140, editable: true,  },
    { field: 'email', headerName: 'Email', width: 180, editable: true, },
    { field: 'so_dien_thoai', headerName: 'Số điện thoại', width: 110, editable: true,},
       { field: 'ten_tinh', headerName: 'Tên tỉnh', width: 110,},
   { field: 'ten_truongTHPT', headerName: 'Tên trường THPT', width: 250, },
   { field: 'tenLop', headerName: 'Lớp', width: 40, editable: true,},
   { field: 'maTruong', headerName: 'Mã trường', width: 80, },
   { field: 'nganh', headerName: 'Ngành', width: 280, },
   { field: 'namtuyensinh', headerName: 'Năm', width: 80,  },
    
    {
      field: 'Status',
      headerName: 'Trạng thái',
      width: 160,
      editable: true,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case 'Đã đăng ký':
            color = 'warning';
            break;
          case 'Đã nhập học':
            color = 'success';
            break;
          default:
            color = 'default';
        }
        return (
          <Chip label={params.value} color={color} />
        );
      },
      renderEditCell: (params) => {
        return (
          <Select
            value={params.value}
            onChange={(event) => {
              const newValue = event.target.value;
              params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event);
              params.api.stopCellEditMode({ id: params.id, field: params.field });
      
              // Call your backend API to update the database
              fetch(`${process.env.REACT_APP_API_URL}/api/dangkyhocbong/update`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  id: params.id,
                  Status: newValue,
                  // Include other fields if necessary
                }),
              })
              .then(response => response.json())
              .then(data => {
                // Handle the response from your backend
                console.log('Update successful', data);
              })
              .catch(err => {
                console.error('Error updating data', err);
              });
            }}
            fullWidth
          >
            <MenuItem value="Chưa đăng ký">Chưa đăng ký</MenuItem>
            <MenuItem value="Đã đăng ký">Đã đăng ký</MenuItem>
            <MenuItem value="Đã nhập học">Đã nhập học</MenuItem>
            {/* Add more MenuItems for other statuses as needed */}
          </Select>
        );
      },
    },
   
    {
      field: 'actions',
      headerName: 'Hành động',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.id)}>
          <DeleteIcon />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

];
// Bây giờ bạn có thể quyết định sử dụng cột nào dựa trên vai trò
// Tại đầu component `DanhSachThiSinh`, bạn có thể thêm state để kiểm tra vai trò người dùng


  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'ho_ten', headerName: 'Họ tên', width: 150, editable: true, },
    { field: 'can_cuoc_cong_dan', headerName: 'Căn cước công dân', width: 180, editable: true,  },
    { field: 'email', headerName: 'Email', width: 150, editable: true, },
    { field: 'so_dien_thoai', headerName: 'Số điện thoại', width: 110, editable: true,},
    { field: 'tenLop', headerName: 'Lớp', width: 40, editable: true,},
    { field: 'nganh', headerName: 'Ngành', width: 280, },
    { field: 'namtuyensinh', headerName: 'Năm', width: 80,  },
    {
      field: 'Status',
      headerName: 'Trạng thái',
      width: 140,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case 'Đã đăng ký':
            color = 'warning';
            break;
          case 'Đã nhập học':
            color = 'success';
            break;
          default:
            color = 'default';
        }
        return (
          <Chip label={params.value} color={color} />
        );
      },
      
    },
    
    {
      field: 'actions',
      headerName: 'Hành động',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.id)}>
          <DeleteIcon />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const columnsToUse = isAdmin ? adminColumns : columns;

  // useEffect(() => {
  //   const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  //   if (userInfo && userInfo.maTruong) {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/dangkyhocbong/${userInfo.maTruong}`);
  //         if (!response.ok) {
  //           throw new Error('Data could not be fetched!');
  //         } else {
  //           const fetchedData = await response.json();
  //           setData(fetchedData.map((item) => ({
  //             ...item,
  //             id: item.id,
  //             Status: item.Status, // Use the actual status value from the data
  //           }))); // Ensure each row has an 'id' field
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data: ", error);
  //       }
  //     };
  //     fetchData();
  //   } else {
  //     console.log('User info not found');
  //   }
  // }, []);
  // useEffect(() => {
  //   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
  //   // Kiểm tra xem userInfo có tồn tại và có role không
  //   if (!userInfo || !userInfo.role) {
  //     console.error("User info not found or user is not authorized");
  //     return;
  //   }
  
  //   let url = `${process.env.REACT_APP_API_URL}/api/dangkyhocbong`;
  //   if (userInfo.role !== 'admin') {
  //     // Nếu người dùng không phải là admin, thêm mã trường vào query để lọc theo mã trường
  //     url += `/${userInfo.maTruong}`;
  //   }
  
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error('Data could not be fetched!');
  //       }
  //       const fetchedData = await response.json();
  //       setData(fetchedData.map((item) => ({
  //         ...item,
  //         id: item.id,
  //         Status: item.Status, // Use the actual status value from the data
  //       }))); // Ensure each row has an 'id' field
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //     }
  //   };
  
  //   fetchData();
  // }, []);





  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    
    if (!userInfo || !userInfo.role) {
      console.error("User info not found or user is not authorized");
      return;
    }
    
    let url = `${process.env.REACT_APP_API_URL}/api/dangkyhocbong`;
    if (userInfo.role !== 'admin') {
      // Nếu người dùng không phải là admin, thêm mã trường vào URL để lọc theo mã trường
      url += `?maTruong=${userInfo.maTruong}`;
      console.log('Fetching data for user with maTruong:', userInfo.maTruong);
    } else {
      console.log('Fetching data for admin');
    }
    
    const fetchData = async () => {
      try {
        console.log('Making request to:', url);
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Data could not be fetched! Status: ${response.status}`);
        }
        const fetchedData = await response.json();
        setData(fetchedData.map((item) => ({
          ...item,
          id: item.id,
          Status: item.Status,
        })));
        console.log('Data fetched successfully');
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    
    fetchData();
  }, []);
  return (
    <ThemeProvider theme={theme}>
        {/* Thêm nút để kích hoạt chức năng xuất dữ liệu */}
      {/* <Button onClick={() => exportToCSV(data, 'danh_sach_thi_sinh')}>Xuất CSV</Button>
      <Button onClick={() => exportToExcel(data, 'danh_sach_thi_sinh')}>Xuất Excel</Button> */}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
    {/* Nút xuất CSV */}
    {/* <IconButton color="primary" aria-label="export to CSV" onClick={() => exportToCSV(data, 'danh_sach_thi_sinh')}>
      <FileDownloadIcon />
    </IconButton> */}

    {/* Nút xuất Excel */}
    {/* <IconButton color="primary" aria-label="export to Excel" onClick={() => exportToExcel(data, 'danh_sach_thi_sinh')}>
      <TableChartIcon />
    </IconButton> */}
  </div>
{/* Nút để kích hoạt menu xuất dữ liệu */}
{/* <IconButton
        color="primary"
        aria-label="tùy chọn xuất"
        aria-controls="export-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FileDownloadIcon />
      </IconButton> */}
<Button
  color="primary"
  aria-label="tùy chọn xuất"
  startIcon={<SaveAltIcon />}
  onClick={handleClick}
>
  Tải về
</Button>
      {/* Menu cho các tùy chọn xuất */}
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {/* Mục menu để xuất ra CSV */}
        <MenuItem onClick={() => {
          exportToCSV(data, 'danh_sach_thi_sinh');
          handleClose();
        }}>
          <DescriptionIcon />
          {' '}Tải về CSV
        </MenuItem>

        {/* Mục menu để xuất ra Excel */}
        <MenuItem onClick={() => {
          exportToExcel(data, 'danh_sach_thi_sinh');
          handleClose();
        }}>
          <BackupTableIcon />
          {' '}Tải về Excel
        </MenuItem>
      </Menu>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columnsToUse} // Sử dụng biến `columnsToUse` ở đây
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          // Enable/disable features here
          disableSelectionOnClick
          // Add other DataGrid props as needed
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          experimentalFeatures={{ newEditingApi: true }} // Sử dụng API chỉnh sửa mới nếu đang dùng phiên bản mới của MUI
          onRowEditCommit={(params) => {
            handleProcessRowUpdate(params);
          }}
        />
      </div>
    </ThemeProvider>
  );
}

export default DanhSachThiSinh;