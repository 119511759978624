import React from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


// Tạo một theme mới.
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500],
    },
  },
});

function TheLeChuongTrinh() {
  return (
    <ThemeProvider theme={theme}>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" component="h2" 
          sx={{
            textAlign: 'center',
          marginBottom: '0px',
          fontWeight: 'bold', // Làm đậm chữ
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, // Tăng kích cỡ chữ dựa trên kích thước màn hình
        }}>
            CHƯƠNG TRÌNH HỌC BỔNG <br/> KHƠI NGUỒN TRÍ TUỆ - KHUYẾN KHÍCH HỌC TẬP TÂN SINH VIÊN 2024
          </Typography>
          <Typography
        component="h2"
        variant="subtitle1"
        align="center"
        sx={{
          marginBottom: '20px',
          fontSize: { xs: '0.8rem', sm: '1rem' }, // Điều chỉnh kích cỡ chữ cho phù hợp với màn hình
          fontWeight: 'normal', // Không đậm
          color: 'text.secondary', // Sử dụng màu sắc phụ cho subtitle
        }}
      >
            Chinh phục tương lai rực rỡ cùng Học bổng Khơi nguồn tri thức 2024 của Đại học Kiến trúc Đà Nẵng!
          </Typography>
          <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: 0, // Khoảng cách dưới tiêu đề
        }}
      >
        1.	Mục tiêu chương trình học bổng Khơi nguồn trí tuệ 2024
      </Typography>
      <Typography component="span">
      Với mong muốn tiếp thêm động lực cho sinh viên từ những địa phương xa hoặc khu vực khó khăn trên hành trình học tập và giảm bớt gánh nặng tài chính cho gia đình thí sinh. Năm học 2024-2025 Trường Đại học Kiến trúc Đà Nẵng tiếp tục dành tặng <Typography component="span" sx={{ fontWeight: 'bold' }}>1000 suất học bổng miễn từ 50% - 100% học phí</Typography> học kỳ đầu tiên cho Tân sinh viên khóa 2024 khi đăng ký xét tuyển và nhập học vào các ngành học được nêu sau đây. 
     </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: 0, // Khoảng cách dưới tiêu đề
        }}>
        2. Đối tượng áp dụng:
      </Typography>
      <Typography
        component="h2"
        variant="subtitle1"
        
        sx={{
          marginBottom: '0px',
          fontSize: { xs: '0.8rem', sm: '1rem' }, // Điều chỉnh kích cỡ chữ cho phù hợp với màn hình
          fontWeight: 'normal', // Không đậm
           // Sử dụng màu sắc phụ cho subtitle
        }}
      >
        <Typography component="span" sx={{ fontWeight: 'bold' }}>Tất cả các bạn học sinh</Typography> đam mê, sáng tạo và mong muốn theo đuổi các ngành học: 
      </Typography>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>STT</TableCell>
              <TableCell>Mã ngành</TableCell>
              <TableCell>Tên ngành</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Nhóm ngành kỹ thuật */}
            <TableRow>
              <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>Nhóm ngành kỹ thuật (Học bổng 100% Học phí học kỳ đầu tiên)</TableCell>
            </TableRow>
            {/* ...Thêm các TableRow và TableCell tương tự cho các ngành... */}
            <TableRow>
      <TableCell>1</TableCell>
      <TableCell>7580205</TableCell>
      <TableCell>Kỹ thuật xây dựng công trình giao thông</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>2</TableCell>
      <TableCell>7580210</TableCell>
      <TableCell>Kỹ thuật cơ sở hạ tầng</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>3</TableCell>
      <TableCell>7580105</TableCell>
      <TableCell>Quy hoạch vùng và đô thị</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>4</TableCell>
      <TableCell>7580201</TableCell>
      <TableCell>Kỹ thuật xây dựng</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>5</TableCell>
      <TableCell>7580302</TableCell>
      <TableCell>Quản lý xây dựng</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>6</TableCell>
      <TableCell>7510301</TableCell>
      <TableCell>Công nghệ kỹ thuật điện, điện tử</TableCell>
    </TableRow>
            {/* Nhóm ngành kinh tế & Ngoại ngữ */}
            <TableRow>
              <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>Nhóm ngành kinh tế & Ngoại ngữ (Học bổng 50% Học phí học kỳ đầu tiên)</TableCell>
            </TableRow>
            {/* ...Thêm các TableRow và TableCell tương tự cho các ngành... */}
            <TableRow>
      <TableCell>1</TableCell>
      <TableCell>7810201</TableCell>
      <TableCell>Quản trị khách sạn</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>2</TableCell>
      <TableCell>7220201</TableCell>
      <TableCell>Ngôn ngữ Anh</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>3</TableCell>
      <TableCell>7340101</TableCell>
      <TableCell>Quản trị kinh doanh</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>4</TableCell>
      <TableCell>7340301</TableCell>
      <TableCell>Kế toán</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>5</TableCell>
      <TableCell>7340201</TableCell>
      <TableCell>Tài chính ngân hàng</TableCell>
    </TableRow>
          </TableBody>
        </Table>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: 0, // Khoảng cách dưới tiêu đề
        }}
      >
        3. Quy trình đăng ký đơn giản:
      </Typography>
      <List sx={{ marginLeft: 3, marginTop: 0, marginBottom: 0 }}> {/* Điều chỉnh lề và khoảng cách */}
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Bước 1:</Typography> Đăng ký nhận học bổng và ngành học tại link: <Link href="https://hocbong.dau.edu.vn">https://hocbong.dau.edu.vn</Link>
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Bước 2:</Typography> Đăng ký xét tuyển sớm tại link: <Link href="https://xettuyen.dau.edu.vn">https://xettuyen.dau.edu.vn</Link>
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Bước 3:</Typography> Đặt <Typography component="span" sx={{ fontWeight: 'bold', color: 'red' }}>Nguyện vọng 1</Typography> vào <Typography component="span" sx={{ fontWeight: 'bold', color: 'red' }}>Mã trường KTD</Typography> và ngành học mong muốn nhận học bổng trên cổng Tuyển sinh của Bộ GD&ĐT. 
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Bước 4:</Typography> Nhập học đúng ngành học đã đăng ký theo kế hoạch tuyển sinh của trường.
        </ListItem>
      </List>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: 0, // Khoảng cách dưới tiêu đề
        }}
      >
        4. Chinh phục học bổng với điều kiện dễ dàng:
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: -1, // Khoảng cách dưới tiêu đề
        }}
      >
        4.1. Xét tuyển theo học bạ THPT:
      </Typography>
      <List sx={{ marginLeft: 3, marginTop: 0, marginBottom: 0 }}> {/* Điều chỉnh lề và khoảng cách */}
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Điểm Trung bình 5 học kỳ (HK) (HK1,2 lớp 10,11 và HK1 lớp 12) &gt;=  7.0 điểm.
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Hoặc Điểm TBCN 12 &gt;= 7.0 điểm.
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Hoặc Tổng điểm 3 môn 1 trong 06 tổ hợp sau (A00, A01, B00, D01, D14, D15) &gt;=  21 điểm.
        </ListItem>
      </List>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          fontWeight: 'bold', // Đậm chữ
          marginBottom: -1, // Khoảng cách dưới tiêu đề
        }}
      >
        4.2. Xét tuyển theo điểm thi tốt nghiệp THPT 2024:
      </Typography>
      <List sx={{ marginLeft: 3, marginTop: 0, marginBottom: 0 }}> {/* Điều chỉnh lề và khoảng cách */}
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Tổng điểm 3 môn 1 trong 06 tổ hợp sau (A00, A01, B00, D01, D14, D15) &gt;=  17 điểm.
        </ListItem>
      </List>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Học bổng Khơi nguồn tri thức 2024</Typography> là cơ hội tuyệt vời để bạn <Typography component="span" sx={{ fontWeight: 'bold' }}>khẳng định bản thân, phát triển tài năng</Typography> và <Typography component="span" sx={{ fontWeight: 'bold' }}>đón đầu tương lai</Typography>. Hãy nhanh tay đăng ký và <Typography component="span" sx={{ fontWeight: 'bold' }}>chinh phục cánh cửa tri thức</Typography> cùng Đại học Kiến trúc Đà Nẵng!<br/>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Đừng bỏ lỡ cơ hội vàng này!</Typography><br/>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Liên hệ:</Typography><br/>
          <List sx={{ marginLeft: 3, marginTop: 0, marginBottom: 0 }}> {/* Điều chỉnh lề và khoảng cách */}
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Hội đồng tuyển sinh Trường Đại học Kiến trúc Đà Nẵng
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Website: <Link href="https://tuyensinh.dau.edu.vn">https://tuyensinh.dau.edu.vn</Link>
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Fanpage: <Link href="https://facebook.com/daihockientrucdanang">https://facebook.com/daihockientrucdanang</Link>
        </ListItem>
        <ListItem sx={{ padding: 0, display: 'list-item', listStyleType: 'disc' }}>
        Điện thoại: 0816988288 - 0866254999
        </ListItem>
      </List>
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
            Hãy theo dõi fanpage của Đại học Kiến trúc Đà Nẵng để cập nhật những thông tin mới nhất về chương trình học bổng!
          </Typography><br/>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            Chúc bạn thành công!
          </Typography>
        </Box>
    </ThemeProvider>
  );
}

export default TheLeChuongTrinh;
