import React, { useState,useEffect} from 'react';
import { Link,Divider,List, ListItem,ListItemText, Box, Typography, Grid, Card,CardContent, Modal,IconButton,Paper} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import backgroundImage from '../assets/images/bg4.jpg'; // Path to your background image
import logo from '../assets/images/logo.png'; // Path to your background image
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';
import DangKyHocBong from './DangKyHocBong';
import DanhSachThiSinh from './DanhSachThiSinh';
import ThayDoiMatKhau from './ThayDoiMatKhau';
import SchoolIcon from '@mui/icons-material/School'; 
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ListIcon from '@mui/icons-material/List';
import LockIcon from '@mui/icons-material/Lock';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import StickyNote2TwoToneIcon from '@mui/icons-material/StickyNote2TwoTone';
import TheLeChuongTrinh from './TheLeChuongTrinh';
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500], 
    },
    background: {
      default: '#ffffff', 
      paper: '#ffffff', 
    },
  },
  
});

// Định nghĩa style cho modal
const modalStyle = {
  // styles cũ của bạn
  '@media (max-width:600px)': {
    width: '80%', // Hoặc một tỷ lệ phần trăm khác phù hợp
    maxHeight: '90vh',
    overflowY: 'auto', // Cho phép cuộn nếu nội dung quá cao
    p: 2,
  },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto', // Adjusted to auto to take the width of its content
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    overflow: 'hidden', // Add overflow hidden to handle the case where the modal content is too wide
     maxWidth: 'calc(100% - 64px)', // Ensure there's some padding from the viewport edges
     maxHeight: 'calc(100% - 64px)', // Limit the height of the modal
    overflowY: 'auto', // Allow vertical scrolling within the modal
  };

 
  function StatisticsCard({ title, count, icon, iconBgColor, iconColor, borderColor }) {
    return (
      <Card sx={{ minWidth: 275, margin: 1, padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
  {count}
</Typography>
        </CardContent>
        {icon && (
          <Box sx={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20%',
            backgroundColor: iconBgColor || 'transparent', // Màu nền cho icon
            boxShadow: '0px 3px 6px #00000029', // Bóng mờ cho box
            border: borderColor ? `1px solid ${borderColor}` : 'none', // Viền nếu có
            marginRight: 2,
          }}>
            {React.cloneElement(icon, { style: { fontSize: '2rem', color: iconColor || 'inherit' } })} {/* Thay đổi kích thước và màu sắc icon */}
          </Box>
        )}
      </Card>
    );
  }
  
function AdminPage() {
    const navigate = useNavigate();
  

// Thêm vào phần đầu của component AdminPage
const [soThiSinhChuaDangKy, setSoThiSinhChuaDangKy] = useState(0);
const [soThiSinhDangKy, setSoThiSinhDangKy] = useState(0);
const [soThiSinhNhapHoc, setSoThiSinhNhapHoc] = useState(0);
const [tongSoThiSinh, setTongSoThiSinh] = useState(0);

// }, []); // Chỉ chạy một lần khi component được mount
const [userRole, setUserRole] = useState(null);

useEffect(() => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const isAdmin = userInfo?.role === 'admin';
  
  if (userInfo && userInfo.role) {
    setUserRole(userInfo.role);
  }
  const urlChuaDangKy = isAdmin
    ? `${process.env.REACT_APP_API_URL}/api/sothisinhchuadangky`
    : `${process.env.REACT_APP_API_URL}/api/sothisinhchuadangky?maTruong=${userInfo?.maTruong}`;

  const urlDangKy = isAdmin
    ? `${process.env.REACT_APP_API_URL}/api/sothisinhdangky`
    : `${process.env.REACT_APP_API_URL}/api/sothisinhdangky?maTruong=${userInfo?.maTruong}`;
  const urlNhapHoc = isAdmin
    ? `${process.env.REACT_APP_API_URL}/api/sothisinhnhaphoc`
    : `${process.env.REACT_APP_API_URL}/api/sothisinhnhaphoc?maTruong=${userInfo?.maTruong}`;
  const urlTongSo = isAdmin
    ? `${process.env.REACT_APP_API_URL}/api/tongsothisinh`
    : `${process.env.REACT_APP_API_URL}/api/tongsothisinh?maTruong=${userInfo?.maTruong}`;

    fetch(urlChuaDangKy)
    .then(response => response.json())
    .then(data => setSoThiSinhChuaDangKy(data.soThiSinhChuaDangKy))
    .catch(error => console.error('Error fetching data: ', error));
  
  
    fetch(urlDangKy)
    .then(response => response.json())
    .then(data => setSoThiSinhDangKy(data.soThiSinhDangKy))
    .catch(error => console.error('Error fetching data: ', error));

  fetch(urlNhapHoc)
    .then(response => response.json())
    .then(data => setSoThiSinhNhapHoc(data.soThiSinhNhapHoc))
    .catch(error => console.error('Error fetching data: ', error));

  fetch(urlTongSo)
    .then(response => response.json())
    .then(data => setTongSoThiSinh(data.tongSoThiSinh))
    .catch(error => console.error('Error fetching data: ', error));

}, []);


     
  // Hàm để xử lý việc thoát và quay lại trang đăng nhập
  const handleLogout = () => {
     // Xóa thông tin người dùng khỏi localStorage
  localStorage.removeItem('userInfo');
  localStorage.removeItem('tenTruong');
  localStorage.removeItem('maTruong');

    // Chuyển hướng về trang đăng nhập
    navigate('/login');
  };

  const [tenTruong, setTenTruong] = useState(''); // State để lưu trữ tên trường
  const [maTruong, setMaTruong] = useState(''); // State để lưu trữ mã trường
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Lấy thông tin người dùng từ localStorage
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      setIsAdmin(userInfo?.role === 'admin');
      // Cập nhật tên trường vào state
      setTenTruong(userInfo.tenTruong);
      setMaTruong(userInfo.maTruong); // Giả sử 'maTruong' là key chứa mã trường trong object userInfo
    } else {
      // Nếu không có thông tin, có thể redirect người dùng về trang đăng nhập
      navigate('/login');
    }
  }, [navigate]);


  const [modalState, setModalState] = useState({
    register: false,
    list: false,
    changePassword: false,
  });

  const handleOpenModal = (modalName) => {
    setModalState({ ...modalState, [modalName]: true });
  };

  const handleCloseModal = (modalName) => {
    setModalState({ ...modalState, [modalName]: false });
  };


  
const icons = isAdmin ? [
  {
    icon: <StickyNote2TwoToneIcon sx={{ fontSize: '2rem' }} />,
    text: 'Thể lệ chương trình',
    modalName: 'thelechuongtrinh',
  },
  {
    icon: <ListIcon sx={{ fontSize: '2rem' }} />,
    text: 'Danh sách thí sinh',
    modalName: 'list',
  },
  {
    icon: <LockIcon sx={{ fontSize: '2rem' }} />,
    text: 'Đổi mật khẩu',
    modalName: 'changePassword',
  },
] : [
  {
    icon: <StickyNote2TwoToneIcon sx={{ fontSize: '2rem' }} />,
    text: 'Thể lệ chương trình',
    modalName: 'thelechuongtrinh',
  },
  {
    icon: <PersonAddIcon sx={{ fontSize: '2rem' }} />,
    text: 'Đăng ký thí sinh',
    modalName: 'register',
  },
  {
    icon: <ListIcon sx={{ fontSize: '2rem' }} />,
    text: 'Danh sách thí sinh',
    modalName: 'list',
  },
  {
    icon: <LockIcon sx={{ fontSize: '2rem' }} />,
    text: 'Đổi mật khẩu',
    modalName: 'changePassword',
  },
];
const modalStyledangnhap = {
  // ... other styles remain unchanged
  position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    overflow: 'hidden', // Add overflow hidden to handle the case where the modal content is too wide
    maxWidth: 'calc(100% - 64px)', // Ensure there's some padding from the viewport edges
    maxHeight: 'calc(100% - 64px)', // Limit the height of the modal
    overflowY: 'auto', // Allow vertical scrolling within the modal
  width: {
    xs: 'calc(100% - 32px)', // Full width minus padding on the smallest screens
    sm: 'calc(70% - 32px)',  // 70% width on small screens
    md: 'calc(50% - 32px)',  // 50% width on medium screens
    lg: 'calc(40% - 32px)',  // 40% width on large screens
    xl: 'calc(30% - 32px)'   // 30% width on extra-large screens
  },
  // ... other styles remain unchanged
};

  return (
    <ThemeProvider theme={theme}>
    <Box
    // sx={{
    //   width: '100vw',
    //   height: '100vh',
    //   backgroundImage: `url(${backgroundImage})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    //   display: 'flex',
    //   flexDirection: 'column', // Stack items vertically
    //   alignItems: 'center',
    //   justifyContent: 'flex-start', // Align to the top
      
      
    // }}
    sx={{
      width: '100vw',
      maxWidth: '100%',
      minHeight: '100vh', // Changed from height to minHeight
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start', // This might need to be adjusted if content is being pushed down too much
      overflowY: 'auto', // Ensure that the Box can scroll
    }}
  >

  {/* Logo Box */}
  <Box
  sx={{
    textAlign: 'center',
    width: '100%',
    pt: 4, // Add padding top to push the content down
    zIndex: 1300, // Make sure this is higher than the main content box
    // Remove the absolute positioning if it's there
  }}
>
  <img src={logo} alt="Logo" style={{ maxWidth: '100px' }} />
  <Typography
  variant="h5"
  color="inherit"
  sx={{
    mt: 2, // Add margin top to push the text down from the logo
    fontWeight: 'bold',
    fontSize: '1.4rem', // Default font size for larger screens
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem', // Smaller font size for screens smaller than 'sm'
    },
  }}
>
  TRƯỜNG ĐẠI HỌC KIẾN TRÚC ĐÀ NẴNG
</Typography>
</Box>  
  



    <Box
      sx={{
        width: '100%',
        maxWidth: 760,
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 1,
        boxShadow: 3,
        mt: '64px', // Increase this value to push the box down
        mb: '64px', // Optional: Add bottom margin if needed
        zIndex: 1, // Ensure this is lower than the zIndex of your header
        [theme.breakpoints.down('sm')]: {
          maxWidth: '90%',
          mt: '56px', // Adjust for smaller screens
          p: 2,
        },
      }}
    >


      
       <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Use 'flex-start' to align items to the left or 'flex-end' for right
    cursor: 'pointer',
    // other styles
  }}
>
<Typography
  component="h1"
  variant="h5"
  sx={{
    marginRight: '8px',
    marginBottom: '0',
    fontSize: '1.4rem', // Default font size for larger screens
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem', // Smaller font size for screens smaller than 'sm'
    },
  }}
>
  {userRole === 'admin' ? `Xin chào admin: ${tenTruong}` : `Xin chào: ${tenTruong} - ${maTruong}`}
</Typography>
  <IconButton onClick={handleLogout} color="inherit" sx={{ padding: '0' }}>
    <ExitToAppIcon />
  </IconButton>
</Box>   
            <Box sx={{ flexGrow: 1 }}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}  wrap="wrap">
            <Grid item xs={12} sm={6} lg={6}>
      <StatisticsCard
        title="Tổng thí sinh"
        count={tongSoThiSinh}
        icon={<GroupsIcon />}
        iconBgColor="#FFE4E4"
        iconColor="#FF4D49"
        borderColor="#FFE4E4"
      />
    </Grid>
    <Grid item xs={12} sm={6} lg={6}>
      <StatisticsCard
        title="Thí sinh chưa đăng ký"
        count={soThiSinhChuaDangKy}
        icon={<GroupRemoveIcon />}
        iconBgColor="#ECF2FF"
        iconColor="#2100C4"
        borderColor="#ECF2FF"
      />
    </Grid>
    <Grid item xs={12} sm={6} lg={6}>
      <StatisticsCard
        title="Thí sinh đăng ký"
        count={soThiSinhDangKy}
        icon={<GroupAddIcon />}
        iconBgColor="#EAFBDF"
        iconColor="#72E128"
        borderColor="#EAFBDF"
      />
    </Grid>
    <Grid item xs={12} sm={6} lg={6}>
      <StatisticsCard
        title="Thí sinh nhập học"
        count={soThiSinhNhapHoc}
        icon={<SchoolIcon />}
        iconBgColor="#FFF4DF"
        iconColor="#FDB528"
        borderColor="#FFF4DF"
      />
    </Grid>
    {/* Thêm các Grid item khác nếu bạn có */}
  </Grid>
</Box>

<Grid container justifyContent="center" spacing={2}>
  {icons.map((feature, index) => (
    <Grid item xs={6} sm={3} key={index}> {/* xs=6 để chia mỗi hàng làm đôi, sm=3 để có 4 cột trên màn hình lớn */}
      <Box onClick={() => handleOpenModal(feature.modalName)} sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2
      }}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: '50%',
            p: 2,
            mb: 1,
            borderColor: theme.palette.primary.main,
            borderWidth: '2px',
            borderStyle: 'dotted',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              cursor: 'pointer'
            },
            '& .MuiSvgIcon-root': {
              fontSize: '2rem',
              color: theme.palette.primary.main,
            }
          }}
        >
          {feature.icon}
        </Paper>
        <Typography
          variant="body1"
          align="center"
          sx={{
            color: theme.palette.primary.main,
            fontSize: '1rem'
          }}
        >
          {feature.text}
        </Typography>
      </Box>
    </Grid>
  ))}
</Grid>
    

    </Box>

      </Box>

      <Modal
  open={modalState.thelechuongtrinh}
  onClose={() => {}}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
  disableBackdropClick // Prevent modal close on backdrop click
  disableEscapeKeyDown // Prevent modal close on escape key press
>
   <Box sx={modalStyle}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
      <Typography variant="h6">Thể lệ chương trình</Typography>
      <IconButton onClick={() => handleCloseModal('thelechuongtrinh')}>
        <CloseIcon />
      </IconButton>
    </Box>
          
          <Divider sx={{ my: 1 }} />
          <Box sx={{ overflowY: 'auto', maxHeight: 'calc(80vh - 96px)' }}> 
          <Typography id="custom-modal-description" sx={{ mt: 2 }}>
            <TheLeChuongTrinh/>
         </Typography>
        </Box>
        </Box>
      </Modal>

    <Modal
  open={modalState.register}
  onClose={() => {}}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
  disableBackdropClick // Prevent modal close on backdrop click
  disableEscapeKeyDown // Prevent modal close on escape key press
>
   <Box sx={modalStyle}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
      <Typography variant="h6">Đăng ký thí sinh</Typography>
      <IconButton onClick={() => handleCloseModal('register')}>
        <CloseIcon />
      </IconButton>
    </Box>
    <DangKyHocBong />
  </Box> 
</Modal>
      
      <Modal
        open={modalState.list}
        onClose={() => {}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick 
        disableEscapeKeyDown 
      >
        <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
      <Typography variant="h6">Danh sách thí sinh</Typography>
      <IconButton onClick={() => handleCloseModal('list')}>
        <CloseIcon />
      </IconButton>
    </Box>
          <DanhSachThiSinh />
        </Box>
      </Modal>
      
      <Modal
        open={modalState.changePassword}
        onClose={() => {}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick // Prevent modal close on backdrop click
        disableEscapeKeyDown // Prevent modal close on escape key press
      >
        
        <Box sx={modalStyledangnhap}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
          
      <Typography variant="h6">Đổi mật khẩu</Typography>
      <IconButton onClick={() => handleCloseModal('changePassword')}>
        <CloseIcon />
      </IconButton>
    </Box>
    <ThayDoiMatKhau />
        </Box>
      </Modal>



 <Box
  sx={{
    width: '100%',
    p: 1,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography variant="body2" color="common.white" sx={{ mr: 1 }}>
      2024 © DAU
    </Typography>

    <Link href="https://www.facebook.com/DaihocKientrucDanang" color="inherit" sx={{ mx: 1 }}>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 48 48"> <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path> </svg>

    </Link>
    <Link href="https://zalo.me/dhkientrucdanang" color="inherit" sx={{ mx: 1 }}>
    <img width="22" height="22" src="https://img.icons8.com/color/48/zalo.png" alt="Zalo"/>
    </Link>
  </Box>
    </Box>
  </ThemeProvider>
  );
}

export default AdminPage;