import React, { useState,useEffect} from 'react';
import {TextField,Modal, Button, Box, Typography, Grid, Select, MenuItem, ListSubheader, InputLabel, FormControl,Snackbar, Link,Divider} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { FormHelperText } from '@mui/material';
import MuiAlert from '@mui/material/Alert';


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500], 
    },
    background: {
      default: '#ffffff', 
      paper: '#ffffff', 
    },
  },
  
});

// Array of ngành kỹ thuật
const nganhKyThuat = [
  'Kỹ thuật xây dựng công trình giao thông',
  'Kỹ thuật cơ sở hạ tầng',
  'Quy hoạch vùng và đô thị',
  'Kỹ thuật xây dựng',
  'Quản lý xây dựng',
  'Công nghệ kỹ thuật điện, điện tử',
];

// Array of ngành Kinh tế
const nganhKinhTe = [
  'Quản trị khách sạn',
  'Ngôn ngữ Anh',
  'Quản trị kinh doanh',
  'Kế toán',
  'Tài chính ngân hàng',
];

function DangKyHocBong() {
    const [formData, setFormData] = useState({
        hoTen: '',
        canCuocCongDan: '',
        email: '',
        soDienThoai: '',
        nganh: '',
        namtuyensinh: '2024', // Giá trị mặc định là 2024
        maTruong:'',
        tenLop: '' // Thêm mới trường lớp
        
    });


    const [tenTruong, setTenTruong] = useState('');
    const [tenTinh, setTenTinh] = useState('');
    const [tinhId, settinhId] = useState('');
   
    // từ ID tỉnh lấy tên tỉnh
    useEffect(() => {
      async function fetchTenTinh() {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
          const userInfo = JSON.parse(storedUserInfo);
          setTenTruong(userInfo.tenTruong);
          settinhId(userInfo.tinhId);
        // Cập nhật maTruong trong formData
      setFormData(prevFormData => ({
        ...prevFormData,
        maTruong: userInfo.maTruong // Giả sử userInfo có chứa maTruong
      }));
    
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/getTenTinh/${userInfo.tinhId}`);
            const data = await response.json();
            if (data.success) {
              setTenTinh(data.tenTinh);
            } else {
              console.error('Lỗi khi lấy tên tỉnh từ server');
            }
          } catch (error) {
            console.error('Lỗi kết nối đến server:', error);
          }
        }
      }
    
      fetchTenTinh();
    }, []);
     const [tinhList, setTinhList] = useState([]); // Danh sách tỉnh
     const [truongTHPTList, setTruongTHPTList] = useState([]); // Danh sách trường THPT dựa trên tỉnh đã chọn
    const [formErrors, setFormErrors] = useState({});

    

  
    const handleChange = (event) => {
    const { name, value } = event.target;
     // Validate cho trường căn cước công dân
     if (name === 'canCuocCongDan') {
      if (!/^\d{12}$/.test(value)) {
          // Set lỗi nếu không phải là 12 số
          setFormErrors({
              ...formErrors,
              canCuocCongDan: 'Căn cước công dân phải là 12 số.',
          });
      } else {
          // Clear lỗi nếu đúng
          setFormErrors({
              ...formErrors,
              canCuocCongDan: '',
          });
      }
  }
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear errors if the value is valid
  if (value) {
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  }
    if (name === 'tinh') {
        const tinhSelected = tinhList.find(tinh => tinh.id.toString() === value);
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
            ten_tinh: tinhSelected ? tinhSelected.tenTinh : '',
            ten_truongTHPT: '' // Reset giá trị này vì tỉnh đã thay đổi
        }));
    } else if (name === 'truongTHPT') {
        const truongSelected = truongTHPTList.find(truong => truong.id.toString() === value);
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
            ten_truongTHPT: truongSelected ? truongSelected.tenTruong : ''
        }));
    } else {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }
};

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
    
    // Check for required fields and set error messages
    if (!formData.hoTen.trim()) errors.hoTen = "Họ và tên là bắt buộc.";
    if (!formData.canCuocCongDan.trim()) {
      errors.canCuocCongDan = "Số căn cước công dân là bắt buộc.";
  } else if (!/^\d{12}$/.test(formData.canCuocCongDan)) {
      errors.canCuocCongDan = "Số căn cước công dân phải là 12 chữ số.";
  }
   
    if (!formData.email.trim()) errors.email = "Email là bắt buộc.";
    if (!formData.soDienThoai.trim()) errors.soDienThoai = "Số điện thoại là bắt buộc.";
    if (!formData.nganh.trim()) errors.nganh = "Ngành xét tuyển là bắt buộc.";
    if (!formData.tenLop.trim()) errors.tenLop = "Lớp là bắt buộc.";


// Validate email format again on submit
if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
  errors.email = 'Email không hợp lệ.';
}
    setFormErrors(errors);

     // If there are errors, do not submit the form
     if (Object.keys(errors).length > 0) return;
        // Log dữ liệu formData để kiểm tra trước khi gửi
        console.log('Sending formData to server:', formData);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dangkyhocbong`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    hoTen: formData.hoTen,
                    canCuocCongDan: formData.canCuocCongDan,
                    email: formData.email,
                    soDienThoai: formData.soDienThoai,
                    nganh: formData.nganh, // Make sure to include the nganh field
                    namtuyensinh: formData.namtuyensinh,// Include namtuyensinh as well
                    // tenTinh: formData.tenTinh, // Assuming you have tenTinh in formData
                    // tenTruong: formData.tenTruong, // Assuming you have tenTruong in formData
                    tenTinh: tenTinh, // Now including tenTinh
                tenTruong: tenTruong, // Now including tenTruong
                maTruong: formData.maTruong,
                tenLop: formData.tenLop // Include tenLop as well
                })
            });
    
            if (response.ok) {
                console.log('Registration successful');
                setSnackbarMessage("Chúc mừng bạn đã đăng ký thành công!");
setSnackbarSeverity('success');
setOpenSnackbar(true);
   
                // Reset form after successful registration
                setFormData({
                    hoTen: '',
                    canCuocCongDan: '',
                    email: '',
                    soDienThoai: '',
                    nganh: '',
                    tenLop: ''
                });
                // Thông báo cho người dùng rằng đăng ký thành công
                // alert("Đăng ký thành công!");
            } else {
                // Xử lý lỗi phản hồi không thành công từ server
                setSnackbarMessage("Đăng ký thất bại! Vui lòng thử lại.");
setSnackbarSeverity('error');
setOpenSnackbar(true);
            }
        } catch (error) {
          setSnackbarMessage("Lỗi kết nối đến server. Vui lòng thử lại sau.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
        }
    };

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' hoặc 'error'
    const [openSnackbar, setOpenSnackbar] = useState(false);
// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
  return (
    <ThemeProvider theme={theme}>

<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert 
          onClose={() => setOpenSnackbar(false)} 
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
         
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    
   

    {/* Rest of the content centered in the middle of the page */}
    {/* <Box
      sx={{
        width: '100%',
        maxWidth: 720, // Adjusted for wider form
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 1,
        boxShadow: 3,
        mt: '20px', // Add margin at the top to push it down from the top bar
        zIndex: 1, // Ensure this box is above the absolute elements
        // margin: 'auto', // Căn giữa trên mọi kích thước màn hình
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%', // Kích thước tối đa nhỏ hơn trên màn hình nhỏ
      mt: 2, // Khoảng cách nhỏ hơn từ top bar
      p: 1, // Padding nhỏ hơn
    },
      }}
    > */}
        {/* <Typography
  component="h1"
  variant="h5"
  align="center"
  sx={{
    marginBottom: '30px', // Adds 20px bottom margin
  }}
>
CHƯƠNG TRÌNH HỌC BỔNG 4
</Typography> */}
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Họ và tên"
                name="hoTen"
                value={formData.hoTen}
                onChange={handleChange}
                fullWidth
                
                error={!!formErrors.hoTen}
    helperText={formErrors.hoTen}
    required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Số căn cước công dân"
                name="canCuocCongDan"
                value={formData.canCuocCongDan}
                onChange={handleChange}
                fullWidth
                error={!!formErrors.canCuocCongDan}
    helperText={formErrors.canCuocCongDan}
    required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Tên Tỉnh"
                name="tinh"
                value={tenTinh}
               
                fullWidth
                disabled
    
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Trường ThPT"
                name="truongTHPT"
                value={tenTruong}
               
                fullWidth
               
                disabled
              />
            </Grid>
            
            
            
            <Grid item xs={12} sm={6}>
            <TextField
    label="Tên lớp"
    name="tenLop"
    value={formData.tenLop}
    onChange={handleChange}
    fullWidth
    required
    error={!!formErrors.tenLop}
    helperText={formErrors.tenLop}
    
/>
</Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                error={!!formErrors.email}
    helperText={formErrors.email}
    required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Số điện thoại"
                name="soDienThoai"
                value={formData.soDienThoai}
                onChange={handleChange}
                fullWidth
                error={!!formErrors.soDienThoai}
    helperText={formErrors.soDienThoai}
    required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.nganh}>
            <InputLabel 
    id="nganh-label" 
    
   
  >
   Chọn Ngành xét tuyển
  </InputLabel>
  <Select
    labelId="nganh-label"
    label="Chọn Ngành xét tuyển"
    name="nganh"
    value={formData.nganh}
    onChange={handleChange}
    required
    error={!!formErrors.nganh}
    helperText={formErrors.nganh}
    fullWidth
  >
    <MenuItem value="">
      <em>Chọn Ngành</em>
    </MenuItem>
    <ListSubheader>Khối ngành kỹ thuật - 100% Học phí</ListSubheader>
      {nganhKyThuat.map((nganh) => (
        <MenuItem key={nganh} value={nganh}>
          {nganh}
        </MenuItem>
      ))}
      <ListSubheader>Khối ngành Kinh tế, Ngoại ngữ - 50% Học phí</ListSubheader>
      {nganhKinhTe.map((nganh) => (
        <MenuItem key={nganh} value={nganh}>
          {nganh}
        </MenuItem>
    ))}
  </Select>
  {!!formErrors.nganh && <FormHelperText>{formErrors.nganh}</FormHelperText>}
</FormControl>
            </Grid>

            
            <form>
  {/* Các trường khác của form */}

  {/* Trường ẩn chứa giá trị năm xét tuyển */}
  <input
    type="hidden"
    name="namtuyensinh"
    value="2024"
  />
</form>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                ĐĂNG KÝ
              </Button>
            </Grid>

            
          </Grid>
        </form>
      {/* </Box> */}
    
  </ThemeProvider>
  );
}

export default DangKyHocBong;