import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Snackbar,Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import backgroundImage from '../assets/images/bg4.jpg'; // Path to your background image
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import logo from '../assets/images/logo.png'; // Path to your background image
// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500],
    },
  },
});


// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {

  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Kiểm tra xem trường nhập liệu có trống không
  if (!credentials.username || !credentials.password) {
    setErrors({
      ...errors,
      username: credentials.username ? '' : 'Tên đăng nhập không được bỏ trống.',
      password: credentials.password ? '' : 'Mật khẩu không được bỏ trống.',
    });
    return; // Ngăn chặn việc gửi form nếu có lỗi
  }
    fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        maTruong: credentials.username, // Gửi mã trường như username
        matKhau: credentials.password, // và mật khẩu
      }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Đăng nhập thất bại');
      }
      return response.json();
    })
    .then((data) => {
      // Lưu thông tin người dùng vào localStorage để sử dụng sau này
      localStorage.setItem('userInfo', JSON.stringify(data.user));
      
      // Đăng nhập thành công, chuyển hướng đến trang quản trị
      // On successful login:
      setSnackbarMessage('Đăng nhập thành công!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate('/admin');
      }, 2000);
      // Kiểm tra role của người dùng để chuyển hướng đến trang phù hợp
  // const userRole = data.role; // Lấy role từ phản hồi của server
  // setTimeout(() => {
  //   if (userRole === 'admin') {
  //     navigate('/admindau'); // Nếu là admin, chuyển đến trang admin
  //   } else {
  //     navigate('/admin'); // Nếu là user, chuyển đến trang user
  //   }
  // }, 2000);
    })
    .catch((error) => {
      // Xử lý lỗi đăng nhập ở đây, ví dụ: thông báo cho người dùng
      setSnackbarMessage(error.message || 'Đăng nhập thất bại');
  setSnackbarSeverity('error');
  setOpenSnackbar(true);
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const [errors, setErrors] = useState({ username: '', password: '' });


  return (
    <ThemeProvider theme={theme}>
      {/* <Snackbar
  open={openSnackbar}
  autoHideDuration={3000}
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
    Đăng nhập thành công!
  </Alert>
</Snackbar> */}

<Snackbar
  open={openSnackbar}
  autoHideDuration={3000}
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
    {snackbarMessage}
  </Alert>
</Snackbar>

      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: `theme.spacing(2)`,
        }}
      >

        {/* Logo Box */}
       
        <Box
  sx={{
    position: 'absolute',
    top: theme.spacing(3), // Điều chỉnh khoảng cách từ trên xuống
    textAlign: 'center',
    width: '100%',
    zIndex: 2,
    '& img': {
      maxWidth: '100px', // Logo kích thước mặc định trên màn hình lớn
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90px', // Logo nhỏ lại trên màn hình nhỏ
      },
    },
  }}
>
  {/* <img 
    src={logo} 
    alt="Logo" 
    style={{ 
      maxWidth: '120px', // Kích thước mặc định của logo
      [theme.breakpoints.down('sm')]: {
        maxWidth: '60px', // Kích thước logo trên màn hình nhỏ
      }
    }}
  /> */}

<img src={logo} alt="Logo" />
  <Typography
    variant="h5"
    color="inherit"
    sx={{
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '1.1rem', // Kích thước mặc định của văn bản
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem', // Kích thước văn bản trên màn hình nhỏ
      }
    }}
  >
    TRƯỜNG ĐẠI HỌC KIẾN TRÚC ĐÀ NẴNG
  </Typography>
</Box>

    {/* Form Box */}
  
        <Box
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            p: theme.spacing(4),
            borderRadius: 1,
            boxShadow: 3,
            '@media (max-width:1366px)': {
              // Điều chỉnh giá trị này để phù hợp với thiết kế của bạn
              mt: theme.spacing(10), // Thêm margin-top để đẩy khối xuống một chút
            },
            '@media (max-width:600px)': {
              p: theme.spacing(3), // Giảm padding trên màn hình nhỏ hơn
              maxWidth: '80vw', // Rộng tối đa 90% viewport width trên màn hình nhỏ
            },
          }}
        > 
          <Typography component="h1" variant="h5" gutterBottom align="center">
            Welcome to DAU! 👋
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
            error={!!errors.username}
            helperText={errors.username}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Tên đăng nhập"
              name="username"
              autoComplete="username"
              autoFocus
              value={credentials.username}
              onChange={handleChange}
            />
            <TextField
            error={!!errors.password}
            helperText={errors.password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
              value={credentials.password}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              ĐĂNG NHẬP
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
  sx={{
    width: '100%',
    p: 1,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography variant="body2" color="common.white" sx={{ mr: 1 }}>
      2024 © DAU
    </Typography>

    <Link href="https://www.facebook.com/DaihocKientrucDanang" color="inherit" sx={{ mx: 1 }}>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 48 48"> <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path> </svg>

    </Link>
    <Link href="https://zalo.me/dhkientrucdanang" color="inherit" sx={{ mx: 1 }}>
    <img width="22" height="22" src="https://img.icons8.com/color/48/zalo.png" alt="Zalo"/>
    </Link>
  </Box>
</Box>
    </ThemeProvider>
  );
}

export default Login;