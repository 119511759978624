import React, { useState } from 'react';
import { Button, TextField, Box, Snackbar, Grid} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import MuiAlert from '@mui/material/Alert';
// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: red[500],
    },
  },
});


// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ThayDoiMatKhau() {
    const [passwords, setPasswords] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    // const [passwords, setPasswords] = useState({ newPassword: '', confirmPassword: '' });
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setPasswords({ ...passwords, [name]: value });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      if (passwords.newPassword !== passwords.confirmPassword) {
        setError('Mật khẩu mới và mật khẩu xác nhận không khớp.');
        setSnackbarMessage('Mật khẩu không khớp. Vui lòng thử lại.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const maTruong = userInfo.maTruong; // Sử dụng maTruong làm userId nếu phù hợp với logic của bạn

      fetch(`${process.env.REACT_APP_API_URL}/api/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            maTruong: maTruong,
          oldPassword: passwords.oldPassword, // Giả sử bạn cũng có một trường để nhập mật khẩu cũ trong form
          newPassword: passwords.newPassword,
        }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Có lỗi xảy ra khi thay đổi mật khẩu');
        }
        return response.json();
      })
      .then(data => {
        setSnackbarMessage(data.message || 'Thay đổi mật khẩu thành công!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        
        // Xử lý thêm nếu cần, ví dụ đăng xuất người dùng, v.v.
      })
      .catch(error => {
        setError('Lỗi khi thay đổi mật khẩu: ' + error.message);
        setSnackbarMessage(error.message || 'Thay đổi mật khẩu thất bại');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
    };
    
    const handleCloseSnackbar = () => {
      setOpenSnackbar(false);
    };
  return (
    <ThemeProvider theme={theme}>
    <Snackbar
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    <Grid container justifyContent="center">
    <Grid item xs={12} sm={8} >
   
      
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
      
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="oldPassword"
          label="Mật khẩu cũ"
          type="password"
          id="oldPassword"
          value={passwords.oldPassword}
          onChange={handleChange}
        />
        <TextField
       
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="Mật khẩu mới"
          type="password"
          id="newPassword"
          value={passwords.newPassword}
          onChange={handleChange}
        />
        <TextField
       
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Xác nhận mật khẩu mới"
          type="password"
          id="confirmPassword"
          error={!!error}
          helperText={error}
          value={passwords.confirmPassword}
          onChange={handleChange}
        />
        <Button
       
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          // sx={{ mt: 3, mb: 2 }}
        >
          Thay Đổi Mật Khẩu
        </Button>
      </Box>
   
    </Grid>
</Grid>

  </ThemeProvider>
  );
}

export default ThayDoiMatKhau;